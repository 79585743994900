<button
  type="button"
  [disabled]="disabled"
  [ngClass]="{
    'w-full px-4 py-2 rounded-lg border text-white items-center justify-center inline-flex': true,
    'bg-blue-mid  border-blue-mid': disabled,
    'bg-blue  border-blue': !disabled
  }"
  (click)="handleClick()"
  matRipple
  [matRippleCentered]="true"
  [matRippleDisabled]="disabled"
>
  @if (loading) {
    <span class="h-6 w-1/5">
      <app-loading />
    </span>
  } @else {
    @if (type === buttonType.LEADING_ADD_ICON) {
      <mat-icon class="material-icons-outlined mr-2 self-center">add</mat-icon>
    }

    <span
      class="self-center text-base font-medium"
      [ngClass]="type === buttonType.LEADING_ADD_ICON ? 'text-sm' : 'text-base'"
      >{{ text }}</span
    >
    @if (type === buttonType.TRAILING_LINK_ICON) {
      <mat-icon class="material-icons-outlined ml-2 text-base"
        >arrow_outward</mat-icon
      >
    }
  }
</button>
